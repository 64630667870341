import React, { useEffect, useState } from 'react';
import * as api from '../../api/api';

const GoalEditor = ({
    goal,
    deleteGoal,
}) => {

    const [stateGoal, setStateGoal] = useState(null);

    useEffect(() => {
        if (goal) {
            setStateGoal(goal);
        }
    }
    , [goal]);


    return (
        <div>
            {/* /* add button to add a goal */}
            {stateGoal ? (
                <div
                    style={{
                        fontSize: "18px",
                        whiteSpace: "pre-wrap",
                        borderStyle: 'double',
                        padding: '10px',
                        margin: '10px',
                        width: "80vw",
                        maxWidth: "600px",
                    }}
                >
                    <p>Goal ID: {goal.goalId}</p>

                    <div
                        style={{
                            marginTop: "2px",
                        }}
                    >
                        Start Date: 
                    </div> 
                    <input 
                        type="text" 
                        value={stateGoal.startDate}
                        onChange={(e) => setStateGoal({...stateGoal, startDate: e.target.value})}
                    /> 
                    <div
                        style={{
                            marginTop: "8px",
                        }}
                    >
                        End Dates: 
                    </div> 
                    <input 
                        type="text" 
                        value={stateGoal.endDate}
                        onChange={(e) => setStateGoal({...stateGoal, endDate: e.target.value})}
                    /> 
                    <div
                        style={{
                            marginTop: "8px",
                        }}
                    >
                        Goal Value: 
                    </div> 
                    <input 
                        type="text" 
                        value={stateGoal.goalValue}
                        onChange={(e) => setStateGoal({...stateGoal, goalValue: e.target.value})}
                    /> 
                    <div
                        style={{
                            marginTop: "8px",
                        }}
                    >
                        Goal Unit: 
                    </div> 
                    <select 
                        options={["km", "miles", "minutes", "hours"]}
                        value={stateGoal.goalUnit}
                        onChange={(e) => setStateGoal({...stateGoal, goalUnit: e.target.value})}
                    > 
                        <option value="DISTANCE">Distance</option>
                        <option value="TIME">Time</option>
                        <option value="ACTIVITY_COUNT">Activity Count</option>
                        <option value="ELEVATION_GAIN">Elevation Gain</option>
                    </select>
                    <div
                        style={{
                            marginTop: "8px",
                        }}
                    >
                        Activity Types: 
                    </div> 
                    <input 
                        type="text" 
                        value={stateGoal.activityTypes}
                        onChange={(e) => setStateGoal({...stateGoal, activityTypes: e.target.value})}
                    /> 
                    <button
                        onClick={() => api.updateGoal(stateGoal)}
                    >
                        Save
                    </button>
                    <button
                        onClick={() => deleteGoal()}
                    >
                        Delete
                    </button>
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );

}

export default GoalEditor;