import React, { useState } from 'react';
import AuthContent from './AuthContent';
import GoalsEditor from './goals/GoalsEditor';

const AuthContentWrapper = () => {
    const [isEditingGoals, setIsEditingGoals] = useState(false);

    return (
        <div>
            <button 
                onClick={() => setIsEditingGoals(!isEditingGoals)}
                style={{
                    fontSize: "18px",
                    padding: "10px",
                    margin: "10px",
                }}
            >
                {isEditingGoals ? 'Stop Editing' : 'Edit Goals'}
            </button>
            {isEditingGoals ? (
                <GoalsEditor />
            ) : (
                <AuthContent />
            )}
        </div>
    );

}

export default AuthContentWrapper;
