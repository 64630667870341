
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css';

const Login = ({
    setIsAuthenticated
}) => {
    const firebaseConfig = {    
        apiKey: "AIzaSyAwP-nadPN_t7eXfU5BnK6Gw1TMxEuxOmM",
        authDomain: "running-logs.firebaseapp.com",
        };
        firebase.initializeApp(firebaseConfig);

    const uiConfig = {
        signInFlow: "popup", 
        signInSuccessUrl: '/',
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
        // tosUrl and privacyPolicyUrl accept either url string or a callback
        // function.
        // Terms of service url/callback.
        tosUrl: '/',
        // Privacy policy url/callback.
        privacyPolicyUrl: function() {
            window.location.assign('/');
        }
    };

    const firebaseWidget = document.getElementById('firebaseui-auth-container')
    if (firebaseWidget) {
        if(firebaseui.auth.AuthUI.getInstance()) {
            const ui = firebaseui.auth.AuthUI.getInstance()
            ui.start('#firebaseui-auth-container', uiConfig)
        } else {
            const ui = new firebaseui.auth.AuthUI(firebase.auth())
            ui.start('#firebaseui-auth-container', uiConfig)
        }
    }

    firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
        const firebaseWidget = document.getElementById('firebaseui-auth-container')
        if (firebaseWidget) {
            if (user) {
                // User is signed in.
                const photoURL = user.photoURL;
                user.getIdToken().then(function(accessToken) {
                    localStorage.setItem('accessToken', accessToken)
                    document.getElementById('account-image').src = photoURL;
                    document.getElementById('firebaseui-auth-container').style.display = 'none';
                    document.getElementById('log-out').style.display = 'block';
                });
            } else {
                // User is signed out.
                document.getElementById('account-image').src = '';
                document.getElementById('firebaseui-auth-container').style.display = 'block';
                document.getElementById('log-out').style.display = 'none';
                if(firebaseui.auth.AuthUI.getInstance()) {
                    const ui = firebaseui.auth.AuthUI.getInstance()
                    ui.start('#firebaseui-auth-container', uiConfig)
                } else {
                    const ui = new firebaseui.auth.AuthUI(firebase.auth())
                    ui.start('#firebaseui-auth-container', uiConfig)
                }
            }
        }
        document.getElementById('log-out').addEventListener('click', e => { 
            firebase.auth().signOut();
            localStorage.removeItem('accessToken');
            localStorage.removeItem('progress');
        });
    }, function(error) {
    console.log(error);
    });

    return (
        <div style={{ display: "flex" }} >
            <div id="firebaseui-auth-container"></div>
            <img id="account-image" src="" alt="" />
            <button
                id="log-out"
                style={{ marginLeft: '10px', width: '100px' }}
            >
                Log Out
            </button>

            <img
              src="/images/icon-1024.png" 
              alt="Strava Logo" 
              style={{
                height: '100px',
                marginLeft: '10px',
                marginTop: '2px'
              }}
            />
            <img
              src={require("../images/api_logo_pwrd_by_strava_stack_light.png")} 
              alt="Strava Logo" 
              style={{
                height: '50px',
                marginLeft: '20px',
                marginTop: '25px'
              }}
            />
        </div>
    );
}


export default Login;