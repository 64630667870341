import React, { useEffect, useState } from 'react';
import * as api from '../../api/api';
import GoalEditor from './GoalEditor';

const GoalsEditor = () => {
    const [goals, setGoals] = useState(null);

    const updateGoals = () => {
        api.getGoals().then((goals) => {
            setGoals(
                goals.data.sort((a, b) => {
                    const aEndDateEpoch = new Date(a.endDate);
                    const aStartDateEpoch = new Date(a.startDate);
                    const bEndDateEpoch = new Date(b.endDate);
                    const bStartDateEpoch = new Date(b.startDate);
                    return aEndDateEpoch === bEndDateEpoch ? (aStartDateEpoch > bStartDateEpoch ? -1 : 1) : (aEndDateEpoch > bEndDateEpoch ? -1 : 1);
                })
            );
        });
    };

    useEffect(() => {
        updateGoals();
    }, []);

    return (
        <div>
            <button
                onClick={() => api.createGoal({
                    activityTypes: "RUN",
                    endDate: "01 Jan 2026 00:00:00 GMT",
                    goalUnit: "DISTANCE",
                    goalValue: 1000,
                    startDate: "01 Jan 2025 00:00:00 GMT"
                }).then(() => {
                    updateGoals();
                })
            }
                style={{
                    fontSize: "18px",
                    padding: "10px",
                    margin: "10px",
                }}
            >
                Add Goal
            </button>
            {goals != null && goals.map((goal) => (
                <GoalEditor 
                    key={goal.goalId}
                    goal={goal}
                    deleteGoal={() => {
                        api.deleteGoal(goal.goalId).then(() => {
                            updateGoals();
                        });
                    }}
                />
            ))}
        </div>
    );

}

export default GoalsEditor;
